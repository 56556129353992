<template>
  <router-link :to="route" @click.native="handleClick">{{ humanSeconds }}</router-link>
</template>

<script>
import { convertTimeToSeconds, formatSecondsToTime } from '@/helpers/dates';

export default {
  props: {
    time: {
      type: String,
      required: true,
    },
  },
  computed: {
    seconds() {
      return convertTimeToSeconds(this.time);
    },
    humanSeconds() {
      return formatSecondsToTime(this.seconds);
    },
    route() {
      return {
        query: { t: this.seconds },
      };
    },
  },
  methods: {
    handleClick() {
      this.$emit('click', this.seconds);
    },
  },
};
</script>
