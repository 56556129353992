<template>
  <v-container class="fill-height align-start">
    <template v-if="loading">
      <v-row class="align-self-center" justify="center" align="center">
        <v-progress-circular color="primary" indeterminate />
      </v-row>
    </template>

    <template v-else>
      <v-row justify="center" no-gutters>
        <v-col cols="12" lg="10" xl="9">
          <video-player
            ref="player"
            :source="source"
            :poster="poster"
            :language="locale"
            :initial-volume="0.5"
            :playback-rates="[0.5, 0.75, 1, 1.25, 1.5]"
            :autoplay="autoplay"
            :time="time"
            :tracks="tracks"
            big-play-centered
            show-big-play-on-pause
            @loadstart="handleLoadStart"
            @timeupdate="handleTimeUpdateEvent"
          />

          <video-details class="mt-3 mt-sm-5" :title="name" :date="date" :description="description">
            <template v-slot:chapters>
              <p class="text-pre-line">
                <template v-for="{ id, title, start_time: time } in chapters">
                  <chapter-link :key="id" :time="time" @click="handleChapterClick" /> {{ title }}
                  <br :key="`br-${id}`" />
                </template>
              </p>
            </template>
          </video-details>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import get from 'lodash/get';

import VideoPlayer from '@/components/common/VideoPlayer.vue';
import VideoDetails from '@/components/common/VideoDetails.vue';
import ChapterLink from '@/components/record/ChapterLink.vue';

import Translation from '@/api/http/translation';

import MediaMixin from '@/mixins/media';
import LocaleMixin from '@/mixins/locale';
import PlayerMixin from '@/mixins/player';

import { STATUS_ROUTES } from '@/constants/router';

import { copyValues } from '@/helpers/objects';

function initialRecordData() {
  return {
    state: null,
    name: null,
    description: null,
    link: null,
    preview: null,
    time: null,
    chapters: [],
  };
}

function initialObjectsData() {
  return {
    chapters: null,
  };
}

export default {
  metaInfo() {
    return {
      title: this.name || this.$t('views.record.title'),
    };
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    time: {
      type: Number,
    },
  },
  data() {
    return {
      loading: true,
      record: initialRecordData(),
      objects: initialObjectsData(),
    };
  },
  computed: {
    poster() {
      return this.record.preview;
    },
    playlist() {
      return this.record.link;
    },
    source() {
      return {
        src: this.playlist,
        type: 'application/x-mpegURL',
      };
    },
    name() {
      return this.record.name;
    },
    description() {
      return this.record.description;
    },
    date() {
      return this.record.time;
    },
    chapters() {
      return this.record.chapters;
    },
    autoplay() {
      return this.time >= 0;
    },
    tracks() {
      const { chapters } = this.objects;

      if (!chapters) {
        return;
      }

      return [
        {
          kind: 'chapters',
          src: chapters,
          srclang: this.locale,
          default: true,
        },
      ];
    },
  },
  watch: {
    id() {
      this.fetchRecordData();
    },
  },
  beforeMount() {
    this.fetchRecordData();
  },
  beforeDestroy() {
    this.resetObjects();
  },
  methods: {
    async fetchRecordData() {
      this.loading = true;

      if (!this.id) {
        return this.$router.push({ name: 'notFound' });
      }

      this.resetRecordData();

      return this.fetchTranslation()
        .then(() => this.playlist && this.refreshCredentials(this.playlist))
        .finally(() => {
          this.loading = false;
        });
    },
    async fetchTranslation() {
      return Translation.get(this.id)
        .then(({ data }) => this.parseTranslation(data))
        .catch(({ status }) => {
          const name = STATUS_ROUTES[status];

          if (name) {
            return this.$router.push({ name });
          }

          this.$toast.error(this.$t('views.record.error.translation'));
        });
    },
    parseTranslation(translation = {}) {
      const { name, state, time, ...rest } = translation;

      const meta = get(rest, state, null);
      const chapters = get(meta, 'table_of_contents.chapters', null);

      this.record = copyValues(this.record, {
        ...meta,
        name,
        state,
        chapters,
        time: time && new Date(time),
      });
    },
    resetRecordData() {
      this.record = initialRecordData();

      this.resetObjects();
    },
    resetObjects() {
      Object.values(this.objects).forEach(URL.revokeObjectURL);

      this.objects = initialObjectsData();
    },
    async fetchRecordWebVTT() {
      return Translation.recordWebVTT(this.id)
        .then(({ data }) => this.populateTrackChapters(data))
        .catch(() => {
          this.$toast.error(this.$t('views.record.error.tracks'));
        });
    },
    populateTrackChapters(content = '') {
      const blob = new Blob([content], { type: 'text/vtt' });

      this.objects.chapters = URL.createObjectURL(blob);
    },
    handleLoadStart() {
      this.fetchRecordWebVTT();
    },
    handleTimeUpdateEvent(progress) {
      if (!this.validateProgress(progress)) {
        return;
      }

      this.trackStatisticEvent('record_progress', {
        id: this.id,
        minutes: this.minutes,
      });
    },
    handleChapterClick(seconds) {
      const { player } = this.$refs;

      if (player.currentTime() !== seconds) {
        player.currentTime(seconds);
      }

      player.play();
    },
  },
  components: { VideoPlayer, VideoDetails, ChapterLink },
  mixins: [MediaMixin, LocaleMixin, PlayerMixin],
};
</script>
