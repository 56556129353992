function convertTimeToSeconds(time) {
  const matched = /^(?:(\d{2,}):)?(\d{2,}):(\d{2})(?:\.\d{3})?$/i.exec(time);

  if (!matched) {
    return 0;
  }

  return matched
    .slice(1, 4)
    .reverse()
    .reduce((seconds, value = 0, pow) => {
      return 60 ** pow * Number(value) + seconds;
    }, 0);
}

function formatSecondsToTime(seconds) {
  const hh = Math.floor(seconds / 3600);
  const mm = Math.floor((seconds % 3600) / 60);
  const ss = Math.round(seconds % 60);

  return [hh, mm, ss].map(val => (val < 10 ? `0${val}` : val)).join(':');
}

export { convertTimeToSeconds, formatSecondsToTime };
